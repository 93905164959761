import React from "react";

import { BLOCKS } from "@contentful/rich-text-types";
const Text = ({ children }) => <p className="align-center">{children}</p>;

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      // render the asset accordingly
      return <img src={node.data.target.fixed.src} class="img-fluid" alt="" />;
    }
  },
  renderText: text =>
    text.split("\n").flatMap((text, i) => [i > 0 && <br />, text])
};

export default options;
